<template>
  <div>
    <div class="hero has-background-white-bis">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-narrow-mobile">
              <div class="is-size-2-desktop is-size-3-mobile has-text-weight-bold">إضافاتي</div>
            </div>
            <div class="column is-narrow-mobile">
              <div class="buttons is-pulled-left">
                <a class="button is-primary is-rounded" @click="getPosts()"><b-icon v-if="loadWait === 1" icon="reload" size="sm" /><b-icon v-if="loadWait === 0" icon="sync" size="sm" spin /> <span style="padding-right: 8px"> تحديث</span></a>
                <router-link class="button is-primary is-rounded" :to="{ path: '/new' }"><b-icon icon="plus" size="sm" /> <span style="padding-right: 8px"> إضافة</span></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="container">
        <CategoryIcons />
        <div class="grid">
          <div class="grid-item" v-for="(value, key) in posts" :key="key">
            <div class="card">
              <div class="card-image" v-if="value.postImage">
                <figure class="image">
                  <template v-if="value.postImage"><img :src="XbortGetMedia(value.postImage, 'medium')" /></template>
                  <template v-else><img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"></template>
                </figure>
              </div>
              <div class="card-content">
                <p class="is-size-4-touch is-size-3-desktop"><a @click="previewPost(value)">{{ value.postTitle | htmlParse }}</a></p>
                <div class="level">
                  <div class="level-left">
                    <p class="content is-small">
                      {{ value.postDate | formatDate }}
                    </p>
                  </div>
                  <div class="level-right">
                    <p class="content">
                      <template v-if="value.postStatus === 'Publish' && value.postPin === 'No'"><b-icon class="has-text-success" icon="check-circle" /></template>
                      <template v-else-if="value.postStatus === 'Draft'"><b-icon class="has-text-grey-light" icon="circle" /></template>
                      <template v-else-if="value.postPin === 'Pin'"><b-icon class="has-text-primary" icon="pin" /></template>
                      <template v-else></template>
                    </p>
                  </div>
                </div>
                <div class="level">
                  <div class="level-left">
                    <router-link class="button is-primary is-rounded is-small" :to="{ path: `/edit/${value._id}` }"><b-icon icon="pencil" /></router-link>
                    <a class="button is-danger is-rounded is-small" @click="deletePost(value._id)" style="margin-right: 8px;"><b-icon icon="delete" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav class="level">
          <a class="level-item has-text-primary" v-if="!gotPosts"><b-icon icon="sync" size="lg" spin /></a>
          <a @click="morePosts" class="level-item has-text-primary" v-if="gotPosts" >
            <b-icon icon="chevron-down-circle" size="lg" />
          </a>
        </nav>
      </div>
    </section>
    <preview :post="postData" :preview="postPreview" :profiles="getProfiles"></preview>
    <div @click="previewPost" id="postPre"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Preview from '../components/Preview'
import CategoryIcons from '../components/CategoryIcons'
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'

export default {
  data () {
    return {
      postData: {
        postTitle: null,
        postLink: null,
        postContent: null,
        postArabicTitle: null,
        postScientificTitle: null,
        postLocalTitle: null,
        postStatus: 'Draft',
        postPin: 'No',
        postDate: new Date().getTime(),
        postImage: null,
        postMedia: [],
        postTags: [],
        postCategory: {},
        postLocation: {
          lat: 24.503418291580818,
          lng: 39.61098560292976
        },
        postType: 'article',
        postAuthor: this.getUser
      },
      loadWait: 1,
      posts: [],
      post: null,
      offset: 0,
      gotPosts: false,
      postPreview: false,
      postEle: null
    }
  },
  components: {
    CategoryIcons,
    Preview
  },
  created () {
    this.$store.dispatch('loadProfiles')
    this.getPosts()
  },
  mounted () {
    this.postEle = document.getElementById('postPre')
  },
  updated () {
    this.postsGrid()
  },
  methods: {
    getPosts: function (offset, load) {
      this.loadWait = load || 0
      this.offset = offset || 0
      var params = {
        appid: this.$store.state.config.XbAppID,
        type: 'data',
        request: 'km_posts',
        getby: `postType:article,postAuthor:${this.$store.state.login.account.user}`,
        offset: this.offset,
        max: 9
      }
      if (this.$route.query.category && this.$route.query.category !== 'all') {
        params.getby = `postType:article,postAuthor:${this.$store.state.login.account.user},postCategory-_id:${this.$route.query.category}`
      } else {
        params.getby = `postType:article,postAuthor:${this.$store.state.login.account.user}`
      }
      this.XbortGetRequest('', params, result => {
        if (result.result) {
          if (result.data.length > 0 && this.offset > 0) {
            for (let i = 0; i < result.data.length; i++) {
              this.posts.push(result.data[i])
            }
          } else if (this.offset === 0) {
            this.posts = result.data
          }
          this.$store.dispatch('showWaiting', false)
          this.$store.dispatch('showReload', false)
          this.loadWait = 1
          this.gotPosts = true
        } else {
          this.loadWait = 1
          this.errorMessage(result.data.message)
        }
      })
    },
    postsGrid: function () {
      let grid = document.querySelector('.grid')
      if (grid) {
        let masonry = new Masonry('.grid', {
          itemSelector: '.grid-item',
          columnWidth: '.grid-item',
          gutter: 35,
          horizontalOrder: true,
          percentPosition: true,
          originLeft: false
        })
        imagesLoaded(grid).on('progress', function () {
          // layout Masonry after each image loads
          masonry.layout()
        })
      }
    },
    deletePost: function (post) {
      this.$dialog.confirm({
        title: 'حذف الموضوع!',
        message: 'هل أنت متأكد من <b>حذف</b> هذا الموضوع? لا يمكن استعادته بعد الحذف.',
        confirmText: 'حذف الموضوع',
        type: 'is-danger',
        onConfirm: () => {
          let params = {
            appid: this.$store.state.config.XbAppID,
            type: 'data',
            request: 'km_posts',
            id: post
          }
          this.XbortRequest('', 'delete', params, result => {
            if (result.result) {
              this.getPosts(this.offset, this.loadWait)
              this.$toast.open({ message: 'تم الحذف!', type: 'is-success' })
            } else {
              this.errorMessage(result.data)
            }
          })
        }
      })
    },
    morePosts: function () {
      this.gotPosts = false
      let newOffset = this.offset + 9
      this.getPosts(newOffset, 1)
    },
    previewPost: function (post) {
      this.postPreview = !this.postPreview
      if (this.postPreview) {
        if (post.postTitle) {
          this.postData.postTitle = this.htmlParse(post.postTitle)
        }
        if (post.postContent) {
          this.postData.postContent = this.htmlParse(post.postContent)
        }
        if (post.postArabicTitle) {
          this.postData.postArabicTitle = post.postArabicTitle
        }
        if (post.postScientificTitle) {
          this.postData.postScientificTitle = post.postScientificTitle
        }
        if (post.postLocalTitle) {
          this.postData.postLocalTitle = post.postLocalTitle
        }
        this.postData.postStatus = post.postStatus
        this.postData.postPin = post.postPin
        this.postData.postDate = post.postDate
        if (post.postImage) {
          this.postData.postImage = post.postImage
        }
        if (post.postMedia && post.postMedia.length > 0) {
          this.postData.postMedia = post.postMedia
        }
        if (post.postTags && post.postTags.length > 0) {
          this.postData.postTags = post.postTags
        }
        if (post.postCategory) {
          this.postData.postCategory = post.postCategory
          this.categories = post.postCategory.category_name
        }
        if (post.postLocation && post.postLocation.lat && post.postLocation.lng) {
          this.postData.postLocation.lat = post.postLocation.lat
          this.postData.postLocation.lng = post.postLocation.lng
        }
        this.postData.postType = post.postType
        this.postData.postAuthor = post.postAuthor
        this.postEle.style.display = 'block'
      } else {
        this.postEle.style.display = 'none'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProfiles'
    ])
  },
  watch: {
    '$route.query.category': function () {
      this.getPosts()
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.postPreview) {
      this.postPreview = !this.postPreview
      this.postEle.style.display = 'none'
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style scoped>
.buttons {
  margin-top: 10px;
}
@media screen and (min-width: 800px) {
  .grid-item {
    width: 31.5%;
  }
}
@media screen and (max-width: 799px) {
  .grid-item {
    width: 100%;
  }
}
.grid-item {
  float: left;
  margin-bottom: 30px;
}
.card {
  border: 0;
  -webkit-box-shadow: 0px 0px 20px 8px rgba(59, 59, 59, 0.15), 0 0 0 0px rgba(59, 59, 59, 0.15);
  -moz-box-shadow: 0px 0px 20px 8px rgba(59, 59, 59, 0.15), 0 0 0 0px rgba(59, 59, 59, 0.15);
  box-shadow: 0px 0px 20px 8px rgba(125, 125, 125, 0.15), 0 0 0 0px rgba(125, 125, 125, 0.15);
  border-radius: 10px;
}
.image img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.gall img {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
